import React from "react";
import { styled } from "../../stitches.config";
import Link from "../Link";

const NewsListAd = props => {
    const { article } = props;

    console.log("props:", props.activeTab)
    return (
        <>
            {article.text &&
                <Wrapper data-tb-region-item>
                    <Link to={article.link} data-analytics-name={"btn-najnowsze-ad"}>
                        {props.activeTab === "Extra" && <Dot />}
                        <span className="title">{article.text}</span>
                    </Link>
                    <Tag>Reklama</Tag>
                </Wrapper>
            }
        </>
    );
}

const Wrapper = styled('div', {
    borderBottom: "1px solid $grayscale10",
    padding: "14px 0",

    "a": {
        display: "inline",
        borderBottom: "none !important",
        padding: "0px",

    }
})

const Dot = styled('div', {
    width: '6px',
    height: '6px',
    marginRight: '5px',
    marginBottom: '1px',
    borderRadius: '50%',
    content: "",
    display: 'inline-block',
    backgroundColor: "$secondary",
    position: 'relative',
});


const Tag = styled('span', {
    fontSize: "11px",
    color: "#a5a5a5",
    background: "#e3e3e3",
    padding: "2px 6px",
    margin: "0 auto 0 8px",
    lineHeight: "20px",
    "&:hover": {
        textDecoration: "none !important",
        color: "#a5a5a5",
    }
})
export default NewsListAd
