import React from "react";

import Editor from './BNewsListAdEditor'
import EditorWrapper from '../../EditorWrapper'

import NewsListAd from "../../common/NewsListAd";
import EmptyArticle from "../../common/EmptyArticle";

const BNewsListAd = props => {
    let article = EmptyArticle();
    const { settings } = props;
    article.link = settings.link;
    article.text = settings.text;


    return (
        <div className={props.classes} {...props.events} >
            <EditorWrapper {...props} editor={Editor} />
            <NewsListAd
                article={article}
                {...props}
            />
        </div>
    );
};


export default BNewsListAd;