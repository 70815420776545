import React from 'react';

import Link from '../editor/Link'

const BNewsListAd = (props) => {
    return (
        <div>
            {props.children}
            <Link value={props.settings.link} />
            <hr />
            <div className="form-group">
                <label className="col-sm-3 control-label">Tekst</label>
                <div className="col-sm-9">
                    <input type="text" name="text" className="form-control" defaultValue={props.settings.text} placeholder="Tekst" />
                </div>
            </div>
        </div>
    );
}


export default BNewsListAd;